export default {
  getProcedureLibraryAll: '/aps/procedure/library/all', // 分页获取工序基准库数据
  getProcedureListByMax: '/aps/procedure/library/getProcedureListByMax', // 获取大工序列表
  insertProcedureLibrary: '/aps/procedure/library/insert', // 新增工序基准库
  getProcedureLibraryOne: "/aps/procedure/library/one", // 获取工序基准库指定id一条信息
  updateProcedureLibrary: "/aps/procedure/library/update", // 更新工序基准库

  getProcedureList: "/aps/procedure/library/getProcedureList", // 查询工序列表


}
