<template>
  <div id="page-container" class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-button type="primary" @click="insertRootHandler"> 添加类别 </el-button>
      <el-button type="primary" @click="insertHandler"> 添加工艺 </el-button>
      <el-button type="primary" @click="deleteHandler"> 移除 </el-button>
    </div>
    <div ref="mainContent" class="product-category-content">
      <el-container>
        <el-aside width="400px" class="product-category-aside">
          <el-tree
            ref="productCategoryTree"
            :data="productCategoryData"
            default-expand-all
            node-key="id"
            highlight-current
            :check-strictly="true"
            draggable
            :expand-on-click-node="false"
            show-checkbox
          >
            <span
              slot-scope="{ node, data }"
              class="custom-tree-node"
              style="width: 100%"
              @click="handleNodeClick(data)"
            >
              <span :title="data.name">{{ data.name }}</span>
            </span>
          </el-tree>
        </el-aside>
        <el-main>
          <el-form
            ref="updateForm"
            :model="processCategoryInfo"
            :rules="ruleValidate"
            label-width="80px"
          >
            <el-form-item label="名称" prop="name">
              <el-input v-model="processCategoryInfo.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="型号" prop="model">
              <el-input v-model="processCategoryInfo.model" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item
              v-show="processCategoryInfo.parentId !== null"
              label="匹配设备"
              prop="matchFields"
            >
              <el-select
                ref="projectType"
                v-model="processCategoryInfo.matchFields"
                allow-create
                clearable
                filterable
              >
                <el-option
                  v-for="(item1, index1) in bomCategoryData"
                  :key="index1"
                  :label="item1.name"
                  :value="item1.id"
                >
                  {{ item1.name + "(" + item1.bomCategoryName + ")" }}
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="processCategoryInfo.remarks"
                type="textarea"
                :rows="2"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>

            <el-form-item style="display: flex; flex-direction: row; justify-content: end">
              <el-button type="primary" :disabled="submitDisabled" @click="updateHandler">
                保存
              </el-button>
            </el-form-item>
          </el-form>
          <div>
            <el-button type="primary" @click="insertProcessNew"> 添加工艺 </el-button>
            <el-table
              :data="processNewListData"
              :row-class-name="getRowClass"
              style="width: 100%; cursor: pointer"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-table
                    :data="props.row.procedureProvideInfoList"
                    style="width: 100%; cursor: pointer"
                  >
                    <el-table-column
                      label="顺序"
                      width="80"
                      type="index"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="name"
                      label="内容"
                      show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column prop="workingMinute" label="工时" width="60"></el-table-column>
                    <el-table-column prop="sortId" label="排序" width="60"></el-table-column>
                    <el-table-column
                      prop="remarks"
                      label="备注"
                      show-overflow-tooltip
                      width="120"
                    ></el-table-column>
                  </el-table>
                </template>
              </el-table-column>

              <el-table-column
                label="顺序"
                width="80"
                type="index"
                align="center"
              ></el-table-column>
              <el-table-column prop="id" label="ID" width="100"></el-table-column>
              <el-table-column prop="name" label="名称" show-overflow-tooltip></el-table-column>
              <el-table-column prop="auxiliaryMinute" label="辅助工时" width="90"></el-table-column>
              <el-table-column prop="basicMinute" label="基础工时" width="90"></el-table-column>
              <el-table-column prop="peopleNumber" label="人力资源" width="90"></el-table-column>
              <!--              <el-table-column prop="equipmentNumber" label="设备资源" width="120"></el-table-column>-->
              <el-table-column
                prop="frontName"
                label="前置工艺"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="belongingName"
                label="归属工艺"
                show-overflow-tooltip
              ></el-table-column>

              <el-table-column align="center" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    title="修改"
                    @click="handleProcessFlowUpdate(scope.row)"
                  ></el-button>
                  <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    title="删除"
                    @click="handleProcessDelete(scope.row)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-main>
        <process-add
          v-model="processAddModalVisiable"
          :process-category-id="processCategoryId"
          :process-list="processNewListData"
          :offset-height="mainHeight"
          @callback="processAddCallback"
        ></process-add>
        <process-edit
          v-model="processEditModalVisiable"
          :process-new-id="processNewId"
          :process-list="processNewListData"
          :offset-height="mainHeight"
          @callback="processEditCallback"
        ></process-edit>
      </el-container>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar";
import mainHeight from "@/utils/mainHeight";
import Product from "@/api/product";
import { listToTree } from "@/utils/util";
import BomCategory from "@/api/bom-category";
import ProcessAdd from "../process-flow/process-add";
import ProcessEdit from "../process-flow/process-edit";
import ProcessFlow from "@/api/process-flow";

export default {
  name: "Category",
  components: { Avatar, ProcessAdd, ProcessEdit },
  data() {
    return {
      mainHeight: "400px",
      listLoading: false,
      productCategoryData: [],
      // 树选择
      menuSelectData: [],
      submitDisabled: true,
      processCategoryInfo: {
        name: "",
        remarks: "",
        model: "",
        processRouteId: "",
        processRouteName: "",
        matchFields: "",
      },
      ruleValidate: {
        name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
      },
      bomCategoryData: [],
      processAddModalVisiable: false,
      processCategoryId: null,
      processNewListData: [],
      processEditModalVisiable: false,
      processNewId: null,
    };
  },
  mounted() {
    mainHeight(this);
    this.getProcessCategoryAll();
    this.getBomCategoryAll();
  },
  methods: {
    getProcessCategoryAll() {
      const that = this;
      that.listLoading = true;
      this.axios
        .get(Product.getProcessCategoryAll)
        .then((data) => {
          that.productCategoryData = listToTree(data, { idKey: "id" });
        })
        .finally(() => {
          that.listLoading = false;
        });
    },

    getBomCategoryAll() {
      const that = this;
      this.axios.post(BomCategory.getBomCategoryAll, {}).then((data) => {
        that.bomCategoryData = data;
      });
    },

    handleNodeClick(data) {
      // 树节点选中事件
      this.menuSelectData = [data];
      this.processCategoryId = data.id;
      this.getProcessCategoryInfo(data.id);
      this.getProcessFlowAll(data.id);
      this.submitDisabled = false;
    },

    getProcessCategoryInfo(id) {
      // 获取类别分组数据
      const that = this;
      that.axios.get(Product.getProcessCategoryOne, { params: { id: id } }).then((data) => {
        that.processCategoryInfo = data;
      });
    },

    insertRootHandler() {
      // 添加根菜单
      const that = this;
      that
        .$confirm("是否要将此类别添加到一级菜单下?", "添加类别提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = { name: "新类别" };
          that.axios
            .post(Product.insertProcessCategory, params)
            .then(() => {
              that.getProcessCategoryAll();
              that.$message.success("添加成功");
            })
            .finally(() => {
              that.addLoading = false;
            });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "取消添加",
          });
        });
    },

    updateHandler() {
      // 修改
      const that = this;
      that.$refs.updateForm.validate((valid) => {
        if (valid) {
          that.axios.post(Product.updateProcessCategory, that.processCategoryInfo).then(() => {
            that.$message.success("保存成功");
            that.getProcessCategoryAll();
          });
        } else {
          that.$message.error("请检查输入!");
        }
      });
    },

    insertHandler() {
      // 添加
      const that = this;
      if (that.menuSelectData.length > 1 || that.menuSelectData.length === 0) {
        that.$message("请选择一级类别");
        return false;
      }
      if (that.menuSelectData[0].parentId !== null) {
        that.$message("最多添加两级节点");
        return false;
      }
      if (that.menuSelectData.length === 1) {
        // 添加子菜单
        that
          .$confirm("是否要将此工艺添加到所选类别下?", "添加工艺提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            const params = { parentId: that.menuSelectData[0].id, name: "新工艺" };
            that.axios.post(Product.insertProcessCategory, params).then(() => {
              that.menuSelectData = [];
              that.getProcessCategoryAll();
              that.$message.success("添加成功");
            });
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "取消添加",
            });
          });
      }
    },

    deleteHandler() {
      // 删除
      const that = this;
      const productCategoryCheckData = that.$refs.productCategoryTree.getCheckedNodes();
      if (productCategoryCheckData.length === 0) {
        that.$message.warning("请至少勾选一条数据");
        return false;
      }
      that
        .$confirm("是否要删除所选?", "删除提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          const params = [];
          productCategoryCheckData.forEach((item, index) => {
            params.push({ id: item.id });
          });
          that.axios.post(Product.deleteProcessCategory, params).then(() => {
            that.getProcessCategoryAll();
            that.$message.success("删除成功");
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },

    insertProcessNew() {
      const that = this;
      if (that.menuSelectData.length === 0) {
        that.$message("请选择左侧类别");
        return false;
      }

      this.processAddModalVisiable = true;
    },

    processAddCallback() {
      // 添加文章对话框回调事件
      this.getProcessFlowAll(this.processCategoryId);
      this.processAddModalVisiable = false;
    },

    getProcessFlowAll(id) {
      const that = this;
      that.listLoading = true;
      that.subProcessData = [];
      this.axios
        .get(ProcessFlow.getProcessFlowListByCategoryId, { params: { categoryId: id } })
        .then((data) => {
          that.processNewListData = data;
          data.forEach((item, index) => {
            if (item.isSubProcess === 0) {
              that.subProcessData.push(item);
            }
          });
        })
        .finally(() => {
          that.listLoading = false;
        });
    },

    handleProcessFlowUpdate(row) {
      // 修改
      const that = this;
      that.processNewId = row.id;
      that.processEditModalVisiable = true;
    },

    handleProcessDelete(row) {
      // 删除
      const that = this;
      that
        .$confirm("是否要删除所选工序?", "删除工序提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.axios.get(ProcessFlow.deleteProcessFlow, { params: { id: row.id } }).then(() => {
            // 删除table中当前id的数据
            // this.processNewListData.splice(this.processNewListData.findIndex(item => item.id === row.id),1)
            this.getProcessFlowAll(this.processCategoryId);
            that.$message.success("删除成功");
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-category-content {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 210px);
  border: 1px solid #e9ecf0;
  .product-category-aside {
    overflow: auto;
    height: calc(100vh - 210px);
    background-color: #fff;
    border-right: 1px solid #e9ecf0;
    padding: 8px 24px 0px 24px;
    margin: 0px;
  }
}
</style>
