/**
 * 列表数据转树形数据
 * @param data
 * @param options
 * @returns {Array}
 */
export const listToTree = (data, options) => {
  options = options || {};
  const ID_KEY = options.idKey || "id";
  const PARENT_KEY = options.parentKey || "parentId";
  const CHILDREN_KEY = options.childrenKey || "children";

  const tree = [];
  const childrenOf = {};
  let item, id, parentId;

  for (let i = 0, length = data.length; i < length; i++) {
    item = data[i];
    id = item[ID_KEY];
    parentId = item[PARENT_KEY] || 0;
    // every item may have children
    childrenOf[id] = childrenOf[id] || [];
    // init its children
    item[CHILDREN_KEY] = childrenOf[id];
    if (parentId !== 0 && parentId !== -1) {
      // init its parent's children
      childrenOf[parentId] = childrenOf[parentId] || [];
      // push it into its parent's children
      childrenOf[parentId].push(item);
    } else {
      tree.push(item);
    }
  }
  return tree;
};

/* 'yyyy-MM-dd HH:mm:ss'格式的字符串转日期 */
export const stringToDate = (str) => {
  if (str.includes(".")) {
    str = str.split(".")[0];
  }
  if (!str.includes(" ")) {
    str += " 00:00:00";
  }
  const tempStrs = str.split(" ");
  const dateStrs = tempStrs[0].split("-");
  const year = parseInt(dateStrs[0], 10);
  const month = parseInt(dateStrs[1], 10) - 1;
  const day = parseInt(dateStrs[2], 10);
  const timeStrs = tempStrs[1].split(":");
  const hour = parseInt(timeStrs[0], 10);
  const minute = parseInt(timeStrs[1], 10);
  const second = parseInt(timeStrs[2], 10);
  const date = new Date(year, month, day, hour, minute, second);
  return date;
};

export const getTodayDate = () => {
  const now = new Date();
  return dateFormat(now);
};

export const dateFormat = (date) => {
  return commonDateFormat("YY-mm-dd", date);
};

export const commonDateFormat = (fmt, date) => {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
    }
  }
  return fmt;
};

// 进度格式化
export const scheduleFormat = (schedule) => {
  if (schedule > 100) {
    schedule = 100;
  }
  if (schedule.toString().indexOf(".") >= 0) {
    schedule = parseFloat(schedule).toFixed(2);
    schedule = parseFloat(schedule);
  }
  return schedule;
};

/**
 * 行为日志样式
 * @param val
 * @returns {string}
 */
export const getUserStyle = (val) => {
  if (val) {
    return "<b class='text-theme1'>" + val + "</b>";
  }
};

/**
 * 云课堂列表数据转树形数据
 * @param data
 * @param options
 * @returns {Array}
 */
export const listYktToTree = (data, options) => {
  options = options || {};
  const ID_KEY = options.idKey || "catId";
  const PARENT_KEY = options.parentKey || "parentId";
  const CHILDREN_KEY = options.childrenKey || "children";

  const tree = [];
  const childrenOf = {};
  let item, id, parentId;

  for (let i = 0, length = data.length; i < length; i++) {
    item = data[i];
    id = item[ID_KEY];
    parentId = item[PARENT_KEY] || 0;
    // every item may have children
    childrenOf[id] = childrenOf[id] || [];
    // init its children
    item[CHILDREN_KEY] = childrenOf[id];
    if (parentId !== 0 && parentId !== -1) {
      // init its parent's children
      childrenOf[parentId] = childrenOf[parentId] || [];
      // push it into its parent's children
      childrenOf[parentId].push(item);
    } else {
      tree.push(item);
    }
  }
  return tree;
};

export const setUserDisabled = (data, users, single, disabledUser) => {
  data.disabled = single;
  for (let i = 0; i < data.children.length; i++) {
    const item = data.children[i];
    if (item.isParent) {
      item.disabled = single;
    }
    if (item.children && item.children.length > 0) {
      setUserDisabled(item, users, single, disabledUser);
    } else {
      if (item.isParent) {
        item.children = [];
      } else if (disabledUser && disabledUser.includes(data.children[i].mid.toLowerCase())) {
        item.disabled = true;
      } else {
        i -= setIsLock(data, i, users);
      }
    }
  }
};

export const setIsLock = (data, i, users) => {
  data.children[i].disabled = data.children[i].locked;
  data.children[i].deptName = data.name;
  data.children[i].leaf = !data.isParent;
  if (data.children[i].locked && !users.includes(data.children[i].mid.toLowerCase())) {
    data.children.splice(i, 1);
    return 1;
  }
  return 0;
};

export const getProjectTreeFun = (
  data,
  types,
  name,
  code,
  isAccount = false,
  categoryDisable = false
) => {
  let index = 1;
  const categoryMap = {};
  const cabinetMap = {};
  data.categoryList.forEach((item) => {
    item.style = types.category;
    item.uid = index++;
    categoryMap[item.id] = item;
    item.children = [];
    item.isEdit = false;
    item.disabled = categoryDisable;
    item.isErpData = item.erpCategoryId > 0;
  });
  let item, id, parentId;
  for (let i = 0, length = data.cabinetList.length; i < length; i++) {
    item = data.cabinetList[i];
    id = item.id;
    cabinetMap[id] = item;
  }
  for (let i = 0, length = data.cabinetList.length; i < length; i++) {
    item = data.cabinetList[i];
    item.isEdit = false;
    item.uid = index++;
    parentId = item.parentId;
    item.children = item.children || [];
    if (item.style === types.cabinet && categoryMap[parentId]) {
      categoryMap[parentId].children.push(item);
    } else if (!isAccount && cabinetMap[parentId]) {
      cabinetMap[parentId].children = cabinetMap[parentId].children || [];
      cabinetMap[parentId].children.push(item);
    }
  }
  for (let i = 0, length = data.cabinetList.length; i < length; i++) {
    const item = data.cabinetList[i];
    if (item.style === types.cabinet && categoryMap[item.parentId]) {
      item.isErpData = item.erpCabinetId > 0;
      item.pName = categoryMap[item.parentId].name;
      if (item.code) {
        let url = getQrUrl(code, categoryMap, cabinetMap, data.cabinetList[i], types);
        const codes = item.code.split("、");
        if (codes.length > 1) {
          const index = url.lastIndexOf("-");
          const codePre = url.substr(0, index + 1);
          url = codePre + codes.join("、" + codePre);
          url = url.split("、");
        } else {
          url = [url];
        }
        item.url = url;
      }
    }
  }
  return getTreeData(data.categoryList, types.category, name, categoryDisable);
};

export const getQrUrl = (proCode, categoryMap, cabinetCodeMap, cabinet, types) => {
  const re = [];
  appendQrUrl(categoryMap, cabinetCodeMap, cabinet, types, re);
  return proCode + "-" + re.reverse().join("-") + "-" + cabinet.code;
};

export const appendQrUrl = (categoryMap, cabinetCodeMap, cabinet, types, re) => {
  if (cabinet.style === types.cabinet) {
    re.push(categoryMap[cabinet.parentId].code);
  } else {
    appendQrUrl(categoryMap, cabinetCodeMap, cabinetCodeMap[cabinet.parentId], types, re);
  }
};

// 获取根节点
export const getTreeData = (data, type, name, categoryDisable) => {
  return [
    {
      id: undefined,
      uid: "",
      style: 100,
      name: name,
      children: data,
      isEdit: false,
      disabled: categoryDisable,
    },
  ];
};

export const highLightTxt = function (str, highVal) {
  return str.replace(
    new RegExp(
      "(?![^&;]+;)(?!<[^<>]*)(" +
        highVal.replace(/([\^\$\(\)\[\]\{\}\*\.\+\?\|\\])/gi, "\\$1") +
        ")(?![^<>]*>)(?![^&;]+;)",
      "gi"
    ),
    "<b class='text-theme1'>$1</b>"
  );
};
