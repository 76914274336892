<template>
  <div class="process-add-layout">
    <el-dialog title="添加工序" :visible.sync="modalVisiable" width="30%" :height="modalHeight" top="50px" @on-visible-change="modalVisibleChangeHandler">
      <el-form ref="processFlowForm" :model="processFlowInfo" :rules="ruleValidate" label-position="right" label-width="120px">

        <el-form-item prop="procedureLibraryId" label="选择工序">
          <el-select v-model="processFlowInfo.procedureLibraryId" filterable placeholder="选择工序" style="width:300px;">
            <el-option
              v-for="item in processSub"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="front" label="选择前置工序">
          <el-select v-model="processFlowInfo.frontId" filterable placeholder="选择前置工序" style="width:300px;">
            <el-option
              v-for="item in processList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="start" label="是否是起始流程">
          <el-input v-model="processFlowInfo.start" placeholder="默认否，是则填写1"></el-input>
        </el-form-item>

        <el-row>
          <el-col>
            <div class="modal-footer-center">
              <el-button type="primary" @click="processAddHandler">保存</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>
  import ProcessFlow from '@/api/process-flow'
  import ProcedureLibrary from '@/api/procedure-library'
  export default {
    name: 'ProcessFlowAdd',
    components: {

    },
    props: {
      value: Boolean,
      offsetHeight: Number,
      processCategoryId: Number,
      processList: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data () {
      return {
        spinShow: false,
        modalHeight: 0,
        processSub: [],
        modalVisiable: false,
        submitDisabled: false,
        editorHeight: 0,
        processFlowInfo: {
          id: '',
          procedureLibraryId: '',
          categoryId: '',
          frontId: null,
        },
        ruleValidate: {
          procedureLibraryId: [
            { required: true, message: '请选择工序名称', trigger: 'blur' }
          ]
        }
      }
    },
    watch: {
      value (val) {
        // 获取父级作用域变量值,初始化对话框
        this.modalVisiable = val
      },
      offsetHeight (val) {
        this.modalHeight = val
        this.editorHeight = val - 293
      },
      modalVisiable (val) {
        this.submitDisabled = false
        this.$emit('input', val)
        if (val) {
          this.processFlowInfo = {
            id: '',
            procedureLibraryId: '',
            categoryId: this.processCategoryId,
            frontId: null,
          }
        }
      }
    },
    mounted () {

      this.getProcedureList()
    },
    methods: {

      getProcedureList(){
        const that = this;
        that.processSub = [];
        this.axios
          .get(ProcedureLibrary.getProcedureList,)
          .then((data) => {
            that.processSub = data
          })

      },


      processAddHandler () {
        // 保存
        let that = this
        that.$refs['processFlowForm'].validate((valid) => {
          if (valid) {
            that.axios.post(ProcessFlow.insertProcessFlow, that.processFlowInfo).then(res => {
              that.$message.success("保存成功");
              that.$emit('callback')
            })
          } else {
            that.$message.error('请检查输入!');
          }
        })
      },


      modalVisibleChangeHandler (val) {
        // 对话框显示状态
        this.modalVisiable = val
      }
    }
  }
</script>

<style lang="scss" scoped>
  .process-add-layout{
    background: #fff;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }
  .form-left-pane{
    padding: 0px 10px 10px 0px;
  }
  .process-add-layout .el-form-item{
    margin-bottom: 20px;
  }

  .img-tools{
    padding:5px 10px;
    background: rgba(0,0,0,0.03);
    a{
      color: #000;
    }
  }
  .modal-footer-center{
    background: #fff;
    text-align: center;
  }
</style>
